<template>
    <div class="courtisia-module" style="width: 100%">
        <router-view></router-view>
    </div>
</template>

<script>

  import api from '@/api'

  export default {
    name: 'app',
  }
</script>

<style lang="scss">
    @import "assets/scss/main";
</style>
