<template>
    <div style="width: 100%">
        <h3>Editez les crédits ou dettes :</h3>

        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['RevenusDepenses']['CreditImmobilier'])}">Crédit Immobilier / CRD *</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['CreditImmobilier']">
            </div>
            <div class="input_text_stayhome">
                <label>Mens. crédit immobilier</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['CreditImmobilierMensualite']">
            </div>
        </div>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label>Crédits revolving / CRD</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['CreditsRevolving']">
            </div>
            <div class="input_text_stayhome">
                <label>Mens. crédits revolving</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['CreditsRevolvingMensualite']">
            </div>
        </div>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label>Crédits personnels / CRD</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['CreditPersonnels']">
            </div>
            <div class="input_text_stayhome">
                <label>Mens. crédits personnels</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['CreditPersonnelsMensualite']">
            </div>
        </div>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label>Autres crédits et dettes</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['AutresCreditsDettes']">
            </div>
            <div class="input_text_stayhome">
                <label>Mens. autres crédits et dettes</label>
                <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['AutresCreditsDettesMensualite']">
            </div>
        </div>
        <div class="input_text_stayhome" style="margin-top: 15px">
            <label>Trésorerie</label>
            <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['Tresorerie']">
        </div>

        <h5>Problèmes bancaires</h5>
        <div class="input_text_stayhome">
            <select class="form-control" v-model.number="recapitulatif['ProblemBank']">
                <option value="0">--- Veuillez sélectionner ---</option>
                <option value="1">Aucun</option>
                <option value="2">Interdit Bancaire</option>
                <option value="3">Fichage carte</option>
                <option value="4">FICP</option>
                <option value="5">Retard de crédit</option>
                <option value="6">Rejets de prélèvements</option>
                <option value="7">En contentieux</option>
                <option value="8">Surrendettement BDF</option>
            </select>
        </div>

        <div class="input_text_stayhome">
            <select class="form-control" v-model.number="recapitulatif['ProblemBank2']">
                <option value="0">--- Veuillez sélectionner ---</option>
                <option value="1">Aucun</option>
                <option value="2">Interdit Bancaire</option>
                <option value="3">Fichage carte</option>
                <option value="4">FICP</option>
                <option value="5">Retard de crédit</option>
                <option value="6">Rejets de prélèvements</option>
                <option value="7">En contentieux</option>
                <option value="8">Surrendettement BDF</option>
            </select>
        </div>

        <h5>Banques actuelles</h5>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <input class="form-control" type="text" v-model="recapitulatif['Bank']">
            </div>
            <div class="input_text_stayhome">
                <input class="form-control" type="text" v-model="recapitulatif['Bank2']">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InformationsCredit",
        props: {
            recapitulatif: Object,
            champManquant: Boolean
        },
    }
</script>

<style lang="scss" scoped>
    .input_text_stayhome {
        display: inline-block;
        width: 49%;

    input {
        width: 90%;
    }

    select {
        height: 35px !important;
        width: 90%;
    }
    }

    .ligne_input_stayhome {
        margin-top: 15px;
    }

    .champManquant {
        color: red;
    }
</style>