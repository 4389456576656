import axios from 'axios'

class Api {

  /*constructor(url) {
    this.axios = axios.create({
      baseURL: url,
      transformResponse: [function (data, headers) {
        if(headers['content-type'] === 'application/json'){
          const fn = JSON.parse || JSON.decode
          return fn(data);
        }
        return data
      }],
    })
  }*/

  constructor(url) {
    this.axios = axios.create({
      baseURL: url,
        transformResponse: [function (data, headers) {
        if(headers['content-type'] === 'application/json'){
          const fn = JSON.parse || JSON.decode
          return fn(data);
        }
        return data
      }],
        transformRequest: [function (data) {
        try {
          return JSON.stringify(data)
        } catch {
          return JSON.encode(data)
        }
      }]
    })

    this.timer = {}

    this.axios.interceptors.request.use((config) => {
      this.timer[config.baseURL + config.url] = new Date().getTime();

      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    this.axios.interceptors.response.use((response) => {
      var end = new Date().getTime();
      var time = (end - this.timer[response.config.url]) / 1000;

      var data = {
        'action': response.config.method + ' : ' + response.config.url,
        'time': time
      }

      if (this.context)
        data['context'] = this.context

      if (this.compte_login)
        data['login'] = this.compte_login;

      if (this.sonde)
        this.sonde.register(data);

      return response
    }, (err) => {
    })
  }

  configurer(token, cb) {
    this.axios.defaults.headers.common['jeton'] = token || process.env.VUE_APP_DEFAULT_TOKEN;

    this.axios.interceptors.response.use((response) => {
      return response
    }, (err) => {
      if (err.response.status === 403) {
        cb()
      }
      return Promise.reject(err)
    })
  }

  compteCharger() {
    return this.axios.get('/utilisateurs/compte')
  }

  jetonRecuperer(){
    return this.axios.get('/utilisateurs/compte/jeton')
  }

  getSimulation(besoin_financier, montant_bien, duree, nature_bien){
    return this.axios.get('/stay-home/simulation/' + montant_bien + '/' + besoin_financier + '/' + duree + '/' + nature_bien)
  }

  get_recapitulatif(dossier_id) {
    return this.axios.get('/stay-home/' + dossier_id)
  }

  envoi_stayHome(dossier_id, data) {
    return this.axios.post('/stay-home/' + dossier_id, data)
  }
}

const locationName = `${window.location.protocol}//${window.location.href.split('/')[2]}/api/v1/`
const apiUrl = (process.env.VUE_APP_API_URL) ? process.env.VUE_APP_API_URL : locationName

export default new Api(apiUrl)
