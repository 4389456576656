<template>
    <div class="cadre background-gray">
        <div class="cadre-content">
            <div class="entete">
                <h1 style="font-weight: bold; color: white">Étude simplifiée de portage</h1>
            </div>

            <div class="suivis-container">
                <div class="cadre background-white">
                    <div class="cadre-content">
                        <div class="kpis">
                            <div class="kpi">
                                <div class="input_formulaire">
                                    <label v-if="!this.afficher_erreures || this.nature_bien">Nature du bien</label>
                                    <label v-if="this.afficher_erreures && !this.nature_bien" style="color: red">Nature du bien</label>
                                    <div>
                                        <i class="fas fa-cog"></i>
                                        <select v-model="nature_bien">
                                            <option value="0">---Sélectionnez un choix---</option>
                                            <option value="1">Résidence principale</option>
                                            <option value="1">Résidence secondaire</option>
                                            <option value="2">Immobilier habitation loué</option>
                                            <option value="2">Bureaux et Murs commerciaux</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="input_formulaire">
                                    <label  v-if="!this.afficher_erreures || this.montant_bien">Valeur estimée du bien</label>
                                    <label  v-if="this.afficher_erreures && !this.montant_bien" style="color: red">Valeur estimée du bien</label>
                                    <div>
                                        <i class="fas fa-home"></i>
                                        <input type="text" placeholder="Montant : 350 000€" v-model="montant_bien">
                                    </div>
                                </div>

                                <div class="input_formulaire">
                                    <label v-if="!this.afficher_erreures || this.besoin_financier">Besoin financier total</label>
                                    <label v-if="this.afficher_erreures && !this.besoin_financier" style="color: red">Besoin financier total</label>
                                    <div>
                                        <i class="fas fa-credit-card"></i>
                                        <input type="text" v-model="besoin_financier">
                                    </div>
                                </div>

                                <div class="input_formulaire">
                                    <label>Durée maximale du portage</label>
                                    <div class="slidecontainer">
                                        <div class="slider_span">
                                            <span>1an</span>
                                            <span>5an</span>
                                            <span>+10an</span>
                                        </div>
                                        <input style="width: 100%;" type="range" min="1" max="10" value="5" class="slider custom-slider" v-model="duree">
                                    </div>
                                </div>

                                <div class="btn" v-on:click="demander_simulation()" style="font-size: 12px; text-align: center; padding-top: 11px;">Calculer</div>
                            </div>

                            <div class="kpi">
                                <div v-if="resultats_key.length > 0" style="box-shadow: 1px 1px 12px #555;height: 100%" v-on:click="goToEnvoiDossier()">
                                    <div class="entete_resultat" style="background: linear-gradient(to bottom, #0d50cb 13%, #0c274e 100%)">
                                        {{this.resultats_key[0]}}
                                        <div style="font-size: 15px; font-weight: normal; color: white">Paiement loyer mensuel</div>
                                    </div>
                                    <div>
                                        <div class="montant_result">
                                            {{Math.round(this.resultats[this.resultats_key[0]]["MontantDispo"])}} €
                                        </div>
                                        <div class="lib_type_montant">
                                            <div>montant disponible</div>
                                        </div>
                                    </div>
                                    <table class="table_result_simulation">
                                        <tr>
                                            <td v-if="this.resultats[this.resultats_key[0]]['LoyerMin']"></td>
                                            <td style="font-weight: bold">Loyer</td>
                                            <td style="font-weight: bold">Valeur Rachat</td>
                                        </tr>
                                        <tr>
                                            <td v-if="this.resultats[this.resultats_key[0]]['LoyerMin']" style="font-weight: bold">Min.</td>
                                            <td>
                                                <div v-if="this.resultats[this.resultats_key[0]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[0]]['LoyerMin'])}} €</div>
                                                <div v-if="!this.resultats[this.resultats_key[0]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[0]]['Loyer'])}} €</div>
                                            </td>
                                            <td>
                                                <div v-if="this.resultats[this.resultats_key[0]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[0]]['RachatMin'])}} €</div>
                                                <div v-if="!this.resultats[this.resultats_key[0]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[0]]['Rachat'])}} €</div>
                                            </td>
                                        </tr>
                                        <tr v-if="this.resultats[this.resultats_key[0]]['LoyerMin']">
                                            <td v-if="this.resultats[this.resultats_key[0]]['LoyerMin']" style="font-weight: bold">Max.</td>
                                            <td>
                                                {{Math.round(this.resultats[this.resultats_key[0]]['LoyerMax'])}} €
                                            </td>
                                            <td>
                                                {{Math.round(this.resultats[this.resultats_key[0]]['RachatMax'])}} €
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="description_resultat">
                                        {{this.get_description(this.resultats_key[0])}}
                                    </div>
                                </div>
                            </div>
                            <div class="kpi">
                                <div v-if="resultats_key.length > 0" style="box-shadow: 1px 1px 12px #555;height: 100%" v-on:click="goToEnvoiDossier()">
                                    <div class="entete_resultat" style="background: linear-gradient(to bottom, rgba(0, 216, 134, 0.98) 13%, #005526 100%);">
                                        {{this.resultats_key[1]}}
                                        <div style="font-size: 15px; font-weight: normal; color: white">Paiement loyer à terme</div>
                                    </div>
                                    <div class="montant_result">
                                        {{Math.round(this.resultats[this.resultats_key[1]]["MontantDispo"])}} €
                                    </div>
                                    <div class="lib_type_montant">
                                        <div>montant disponible</div>
                                    </div>
                                    <table class="table_result_simulation">
                                        <tr>
                                            <td v-if="this.resultats[this.resultats_key[1]]['LoyerMin']"></td>
                                            <td style="font-weight: bold">Loyer</td>
                                            <td style="font-weight: bold">Valeur Rachat</td>
                                        </tr>
                                        <tr>
                                            <td v-if="this.resultats[this.resultats_key[1]]['LoyerMin']" style="font-weight: bold">Min.</td>
                                            <td>
                                                <div v-if="this.resultats[this.resultats_key[1]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[1]]['LoyerMin'])}} €</div>
                                                <div v-if="!this.resultats[this.resultats_key[1]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[1]]['Loyer'])}} €</div>
                                            </td>
                                            <td>
                                                <div v-if="this.resultats[this.resultats_key[1]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[1]]['RachatMin'])}} €</div>
                                                <div v-if="!this.resultats[this.resultats_key[1]]['LoyerMin']">{{Math.round(this.resultats[this.resultats_key[1]]['Rachat'])}} €</div>
                                            </td>
                                        </tr>
                                        <tr v-if="this.resultats[this.resultats_key[1]]['LoyerMin']">
                                            <td v-if="this.resultats[this.resultats_key[1]]['LoyerMin']" style="font-weight: bold">Max.</td>
                                            <td>
                                                {{Math.round(this.resultats[this.resultats_key[1]]['LoyerMax'])}} €
                                            </td>
                                            <td>
                                                {{Math.round(this.resultats[this.resultats_key[1]]['RachatMax'])}} €
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="description_resultat">
                                        {{this.get_description(this.resultats_key[1])}}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chargement" v-if="chargement">
            <div>
                <b> Chargement en cours... </b>
            </div>
        </div>
    </div>
</template>

<script>

  import api from '@/api'

  export default {
    name: 'Simulateur',
    data() {
      return {
        resultats: [],
          resultats_key: [],
          montant_bien: null,
          nature_bien: 0,
          besoin_financier: 0,
          duree: 5,
          afficher_erreures: false,
          compteur_result: 0,
          chargement: false
      }
    },
    created() {


    },
    methods: {
        demander_simulation() {
            this.afficher_erreures = false;
            this.compteur_result = 0;

            if(!this.montant_bien ||!this.nature_bien || !this.besoin_financier) {
                this.afficher_erreures = true;
            } else {
                this.chargement = true;
                api.getSimulation(this.besoin_financier, this.montant_bien, this.duree, this.nature_bien).then((d) => {
                    this.resultats = d.data;
                    this.resultats_key = [];
                    this.resultats_key = Object.keys(this.resultats);
                    this.chargement = false;

                })
            }
        },
        get_description(lib_societe) {
            switch (lib_societe) {
                case "Portimo" : return "Avec Portimo, le vendeur règle un loyer mensuel qui peut être ajusté en fonction de sa capacité de règlement. La valeur de rachat varie en fonction de la durée du portage et du montant du loyer versé.";
                    // eslint-disable-next-line no-unreachable
                                break;
                case "Leasimo" : return "Avec Leasimo, le vendeur ne règle pas de mensualité pendant la période de Portage. Les loyers sont versés en une fois au terme du portage et sont inclus dans la valeur de rachat indiquée ci-dessus.";
                    // eslint-disable-next-line no-unreachable
                                break;
            }

            return "";
        },
        goToEnvoiDossier() {
            var dossierId = this.$route.params.dossierId;
            this.$router.push({ name: 'EnvoiDossier', params: { dossierId: dossierId } })
        }
    }
  }
</script>

<style lang="scss" scoped>

    .cadre {
        height: auto !important;
    }

    .suivis-container {
        display: flex;

        .cadre {
            width: 50%;
            height: 300px;
            margin-top: 20px;
            margin-left: 25%;
            margin-right: 25%;

            &:not(:last-child) {
                margin-right: 20px;
            }

            @media only screen and (max-width: 870px) {
                width: 100% !important;
                margin-right: 0 !important;
            }
        }

        @media only screen and (max-width: 870px) {
            flex-wrap: wrap;
        }

        .cadre.cadre--encours {
            min-width: 45%;
        }

    }

    .cadre-content {
        padding: 10px !important;
    }

    .entete {
        font-weight: 100;
        color: white;
        background-color: #0e88b1;
        padding: 15px 10px 15px 15px;
        h1 {
            font-weight: 100;
            margin: 0;
        }

        p {
            font-size: .8rem;
            padding-bottom: 15px;
        }
    }

    .kpis {
        display: flex;
        height: 100%;

        label {
            font-weight: 100;
        }

        .kpi {
            flex: 1;
            //text-align: center;
            padding: 3px;
            height: 100%;
        }

        .kpi-value {
            font-size: 1.2rem;
        }
    }

    .slider_span {
        display: flex;
        justify-content: space-between;
    }

    .entete_resultat {
        width: 100%;
        height: 65px;
        text-align: center;
        background-color: #1EAAF1;
        padding-top: 15px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 8px
    }

    .input_formulaire {
        margin-bottom: 10px;

        label {
            margin-bottom: 4px;
            padding-bottom: 0;
        }

        select {
            height: 37px;
            display: inline-block;
            width: 84%;
            margin-left: 0;
            padding-left: 0;
        }

        input {
            height: 37px;
            display: inline-block;
            width: 80%;
            margin-left: 0;
            padding-left: 5px;

        }
    }

    .btn {
        width: 110px !important;
        color: #fff;
        border-color: #4cae4c;
        background: #5cb85c !important;
        border-radius: 6px !important;
        margin-left: auto;
        margin-right: auto;
    }

    .fas {
        display: inline-block;
        width: 30px;
        height: 37px;
        background-color: #eee;
        margin: 0;
        vertical-align: top;
        text-align: center;
        font-size: 18px;
        padding-top: 9px;
        border: 1px solid #ccc;
    }

    .montant_result {
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        margin-top: 10px;
    }

    .lib_type_montant {
        color: #7f909a;
        padding-top: 10px;
        font-size: 14px;
        font-weight: bold;
        text-transform: lowercase;
        text-align: center;
        font-weight: bold;
    }

    .table_result_simulation {
        width: 100%;
        margin: 0;
    }

    td {
        border-bottom: solid 1px #ccc;
        text-align: center;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .description_resultat {
        font-size: 14px;
        text-align: center;
        padding: 8px;

    }

    .background-white {
        margin-left: 10% !important;
        margin-right: 10% !important;
        width: 80% !important;
        max-width: 1000px;
        min-width: 700px;
    }
</style>
