<template>
    <div style="width: 100%">
        <div style="border-bottom: black solid 1px" v-for="(bien, index) in recapitulatif['BiensListe']" v-bind:key="index">
            <div style="width: 70%; display: inline-block">
                <h3>Editez le bien N° {{index + 1}}</h3>
            </div>
            <div style="width: 30%; display: inline-block">
                <div class="btn btn-supprimer" v-on:click="supprimer(index)">X</div>
            </div>

            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && (!bien['MontantEstime'] || bien['MontantEstime'] === '0' || bien['MontantEstime'] === ''))}">Valeur estimée *</label>
                <input class="form-control" type="text" v-model="bien['MontantEstime']">
            </div>

            <label v-bind:class="{'champManquant' : (champManquant && !bien['TypeBienId'])}">Type de propriété *</label>
            <select
                    class="form-control"
                    v-model="bien['TypeBienId']"
            >
                <option :value="null">--- Veuillez sélectionner ---</option>
                <option value="1">Maison</option>
                <option value="2">Maison mitoyenne 1 côté</option>
                <option value="3">Maison mitoyenne 2 côtés</option>
                <option value="4">Appartement</option>
                <option value="5">Terrain agricole</option>
                <option value="6">Terrain constructible</option>
                <option value="7">Bureaux</option>
                <option value="8">Murs commerciaux</option>
                <option value="9">Immeuble</option>
                <option value="10">Autre</option>
                <option value="11">Maison en pierre</option>
                <option value="12">Grange</option>
                <option value="13">Château</option>
                <option value="14">Parking, box</option>
                <option value="15">Hotel particulier</option>
            </select>

            <div class="ligne_input_stayhome">
                <div class="input_text_stayhome">
                    <label v-bind:class="{'champManquant' : (champManquant && !bien['SurfaceParcelle'])}">Surface parcelle *</label>
                    <input class="form-control" type="text" v-model="bien['SurfaceParcelle']">
                </div>
                <div class="input_text_stayhome">
                    <label v-bind:class="{'champManquant' : (champManquant && !bien['SurfaceHabitable'])}">Surface habitable *</label>
                    <input class="form-control" type="text" v-model="bien['SurfaceHabitable']">
                </div>
            </div>
            <div class="ligne_input_stayhome">
                <div class="input_text_stayhome">
                    <label>Année de construction</label>
                    <input class="form-control" type="text" v-model="bien['AnneeConstruction']">
                </div>
                <div class="input_text_stayhome">
                    <label>Nombre de pièces</label>
                    <input class="form-control" type="text" v-model="bien['NbPiece']">
                </div>
            </div>
            <div class="ligne_input_stayhome">
                <div class="input_text_stayhome">
                    <label>Etat du bien</label>
                    <select
                            class="form-control"
                            v-model="bien['EtatBienId']"
                    >
                        <option :value="null">--- Veuillez sélectionner ---</option>
                        <option value="1">Assez Bon</option>
                        <option value="2">Bon</option>
                        <option value="3">Excellent</option>
                    </select>
                </div>
                <div class="input_text_stayhome">
                    <label style="text-align: center">Copropriété</label>
                    <input type="checkbox" v-on:click="function() {
                                        if(bien['NombreLotCopropriete'] || bien['ProcedureEnCoursCopropriete']) {bien['NombreLotCopropriete'] = null; bien['ProcedureEnCoursCopropriete'] = false}
                                        else {bien['NombreLotCopropriete'] = 2}
                                    }">
                </div>
            </div>
            <div class="ligne_input_stayhome" v-if="copropriete || bien['NombreLotCopropriete'] || bien['ProcedureEnCoursCopropriete']">
                <div class="input_text_stayhome">
                    <label>Nombre lots copropriété</label>
                    <input class="form-control" type="text" v-model="bien['NombreLotCopropriete']">
                </div>
                <div class="input_text_stayhome">
                    <label style="text-align: center">Procédure en cours</label>
                    <input type="checkbox" v-model="bien['ProcedureEnCoursCopropriete']">
                </div>
            </div>
            <div class="input_text_stayhome" style="margin-top: 15px">
                <label v-bind:class="{'champManquant' : (champManquant && !bien['AdresseBien']['Adresse'])}">Adresse du bien *</label>
                <input class="form-control" type="text" v-model="bien['AdresseBien']['Adresse']">
            </div>
            <div class="ligne_input_stayhome">
                <div class="input_text_stayhome">
                    <label v-bind:class="{'champManquant' : (champManquant && !bien['AdresseBien']['CodePostal'])}">Code postal *</label>
                    <input class="form-control" type="text" v-model="bien['AdresseBien']['CodePostal']">
                </div>
                <div class="input_text_stayhome">
                    <label v-bind:class="{'champManquant' : (champManquant && !bien['AdresseBien']['Ville'])}">Ville *</label>
                    <input class="form-control" type="text" v-model="bien['AdresseBien']['Ville']">
                </div>
            </div>
        </div>

        <div class="btn btn-nouveau" v-on:click="creerNouveauBien()">Nouveau bien</div>
    </div>
</template>

<script>
    export default {
        name: "InformationsBien",
        props: {
            recapitulatif: Object,
            champManquant: Boolean
        },
        methods: {
            creerNouveauBien() {
                this.recapitulatif['BiensListe'].push(
                    {
                        "TypeBienId": null,
                        "AnneeConstruction" : null,
                        "NbPiece" : null,
                        "AdresseBien" : {
                            "Adresse" : null,
                            "CodePostal" : null,
                            "Ville" : null
                        },
                        "MontantEstime" : null,
                        "SurfaceParcelle" : null,
                        "SurfaceHabitable" : null,
                        "NombreLotCopropriete" : null,
                        "EtatBienId" : null,
                        "ProcedureEnCoursCopropriete" : null
                    }
                )
            },
            supprimer(index) {
                this.recapitulatif['BiensListe'].splice(index, 1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input_text_stayhome {
        display: inline-block;
        width: 49%;
    }

    select {
        height: 35px !important;
    }

    .ligne_input_stayhome {
        margin-top: 15px;
    }

    .btn-nouveau {
        width: 100px !important;
        text-align: center;
        color: #0e88b1 !important;
        float: right !important;
        min-width: 100px !important;
        padding-top: 6px !important;
        margin-top: 10px;
    }

    .btn-supprimer {
        width: 16px !important;
        min-width: 14px !important;
        height: 18px !important;
        padding: 0 !important;
        padding-left: 3px !important;
        background-color: red !important;
        color: white;
        float: right !important;
    }

    .champManquant {
        color: red;
    }

</style>