<template>
    <div class="cadre background-gray">
        <div class="cadre-content">
            <div class="entete_stay_home">
                <div style="width: 70%; display: inline-block">
                    <div class="titre">Récapitulatif du dossier</div>
                </div>
                <div style="width: 30%; display: inline-block">
                    <div class="btn btn-valider" v-on:click="envoyer()">Envoyer</div>
                </div>

                <div class="menu_groupe">
                    <div class="menu_case"
                         v-on:click="function() {etape = 'client_info'}"
                        v-bind:class="etape === 'client_info' ? 'actif' : null"
                    >Client</div>
                    <div class="menu_case"
                         v-on:click="function() {etape = 'adresse_info'}"
                         v-bind:class="etape === 'adresse_info' ? 'actif' : null"
                    >Revenus</div>
                    <div class="menu_case"
                         v-on:click="function() {etape = 'credit_info'}"
                         v-bind:class="etape === 'credit_info' ? 'actif' : null"
                    >Crédits</div>
                    <div class="menu_case"
                         v-on:click="function() {etape = 'bien_info'}"
                         v-bind:class="etape === 'bien_info' ? 'actif' : null"
                    >Biens</div>
                    <div class="menu_case"
                         v-on:click="function() {etape = 'infos_info'}"
                         v-bind:class="etape === 'infos_info' ? 'actif' : null"
                    >Informations</div>
                </div>
            </div>

            <div class="stay-home-container">
                <div class="cadre background-white" style="margin-left: 0 !important;">
                    <!-- ---------------------------------------------------------------------------------------------- Infos clients-->
                    <div v-if="etape === 'client_info'">
                        <InformationsClient
                                :recapitulatif="recapitulatif"
                                :champManquant="champManquant"
                        ></InformationsClient>
                    </div>
                    <div v-if="etape === 'adresse_info'">
                        <InformationsAdresse
                                :recapitulatif="recapitulatif"
                                :champManquant="champManquant"
                        ></InformationsAdresse>
                    </div>
                    <div v-if="etape === 'credit_info'">
                        <InformationsCredit
                                :recapitulatif="recapitulatif"
                                :champManquant="champManquant"
                        ></InformationsCredit>
                    </div>
                    <div v-if="etape === 'bien_info'">
                        <InformationsBien
                                :recapitulatif="recapitulatif"
                                :champManquant="champManquant"
                        ></InformationsBien>
                    </div>
                    <div v-if="etape === 'infos_info'">
                        <InformationsInfo
                                :recapitulatif="recapitulatif"
                                :champManquant="champManquant"
                        ></InformationsInfo>
                    </div>
                </div>
            </div>
        </div>

        <transition
                name="notification-afficher">

            <notification
                    v-if="dossierEnvoyeMessage"
                    :content="'Le dossier a bien été envoyé à votre partenaire StayHome, vous pouvez maintenant fermer votre dossier.'"
                    :type="'success'"
            ></notification>
        </transition>

        <transition>
            <notification
                    v-if="formulaire_incomplet"
                    :content="'Certains champs ne sont pas renseignés'"
                    :type="'error'"
            ></notification>
        </transition>

        <transition>
            <notification
                    v-if="message_error !== ''"
                    :content="message_error"
                    :type="'error'"
            ></notification>
        </transition>

        <div class="chargement" v-if="chargement">
            <div>
                <b> Chargement en cours... </b>
            </div>
        </div>
    </div>
</template>

<script>

    import api from '@/api'
    import InformationsClient from "./InformationsClient";
    import InformationsAdresse from "./InformationsAdresse";
    import InformationsCredit from "./InformationsCredit";
    import InformationsInfo from "./InformationsInfo";
    import InformationsBien from "./InformationsBien";
    import Notification from "../components/Notification";

    export default {
        name: "EnvoiDossier",
        components: {
            InformationsClient,
            InformationsAdresse,
            InformationsCredit,
            InformationsInfo,
            InformationsBien,
            Notification
        },
        data() {
            return {
                recapitulatif: null,
                chargement: false,
                etape: null,
                champManquant: false,
                dossierEnvoyeMessage: false,
                formulaire_incomplet: false,
                message_error : "",
                list_champs_obligatoire : [
                    "CiviliteId",
                    "Nom",
                    "Prenom",
                    "Email",
                    "Telephone",
                    "Adresse",
                    "CodePostal",
                    "Ville",
                    "Commentaire",
                    "Profession",
                    "Age",
                    "CreditImmobilier",
                    "RevenuAnnuel",
                    "FullNamePartner",
                    "EmailPartner",
                    "PhonePartner",
                    "DirectContact",
                    "AdresseBien",
                    "MontantEstime",
                    "TypeBienId"
                ]
            }
        },
        created() {
            this.get_recapitulatif()

        },
        methods: {
            get_recapitulatif() {
                this.chargement = true;
                var dossierId = this.$route.params.dossierId;

                api.get_recapitulatif(dossierId).then((d) => {
                    this.recapitulatif = d.data;
                    this.etape = "client_info";
                    this.chargement = false;

                })
            },
            envoyer() {
                if(!this.chargement) {
                    this.champManquant = false;
                    if (this.verifier()) {
                        this.dossierEnvoyeMessage = false;
                        var dossierId = this.$route.params.dossierId;
                        this.chargement = true;
                        api.envoi_stayHome(dossierId, this.recapitulatif).then((d) => {
                            this.chargement = false;

                            if(d.data["error"]) {
                                this.message_error = d.data["error"];
                                setTimeout(() => {
                                    this.message_error = ''
                                }, 2000)
                            } else {
                                this.dossierEnvoyeMessage = true;
                            }
                        })

                    } else {
                        this.champManquant = true;
                        this.formulaire_incomplet = true;
                        setTimeout(() => {
                            this.formulaire_incomplet = false
                        }, 2000)

                    }
                }
            },
            verifier() {
                return this.verif_objet(true, this.recapitulatif);
            },
            verif_objet(dossier_complet, object) {
                for (const property in object) {
                    if(property === "Conjoint") {
                        //Le conjoint n'est pas obligatoire
                    } else if(typeof object[property] === 'object') {
                        if (Array.isArray(object.property)) {
                            dossier_complet = this.verif_array(dossier_complet, object[property])
                        } else {
                            dossier_complet = this.verif_objet(dossier_complet, object[property])
                        }
                    }  else if(!object[property]) {
                        if(this.list_champs_obligatoire.includes(property)) {
                            dossier_complet = false;
                            console.log(property)
                        }
                    }
                }
                return dossier_complet
            },
            verif_array(dossier_complet, array) {
                array.forEach(function(value, index) {
                    if(index === "Conjoint") {
                        //Le conjoint n'est pas obligatoire
                    } else if(typeof array[index] === 'object') {
                        if (Array.isArray(array[index])) {
                            dossier_complet = this.verif_array(dossier_complet, array[index])
                        } else {
                            dossier_complet = this.verif_objet(dossier_complet, array[index])
                        }
                    }  else if(!array[index]) {
                        if(this.list_champs_obligatoire.includes(index)) {
                            dossier_complet = false;
                            console.log(property)
                        }
                    }
                })
                return dossier_complet
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cadre {
        height: 100%!important;
        padding: 10px
    }

    .stay-home-container {
        display: flex;

        .cadre {
            margin-top: 20px;
            margin-left: 15%;
            margin-right: 15%;
            width: 70%;

            &:not(:last-child) {
                 margin-right: 20px;
             }

            @media only screen and (max-width: 870px) {
                width: 100% !important;
                margin-right: 0 !important;
            }
        }

        @media only screen and (max-width: 870px) {
            flex-wrap: wrap;
        }

        .cadre.cadre--encours {
            min-width: 45%;
        }
    }

    .entete_stay_home {
        font-weight: 100;
        color: white !important;
        background-color: #0e88b1;
        padding: 15px 10px 15px 15px;
        .titre {
            font-weight: 100;
            margin: 0;
            font-size: 30px;
            color: white !important;
        }

        p {
            font-size: .8rem !important;
            padding-bottom: 15px !important;
        }
    }

    .menu_groupe {
        margin-top: 15px;
    }

    .menu_case {
        display: inline-block;
        width: 18%;
        border: solid 1px ;
        height: 35px;
        text-align: center;
        vertical-align: middle;
        padding-top: 7px;
        color: white;
    }

    .input_erreur {
        border: solid 1px red;
    }

    .actif {
        background-color: white;
        color: #0e88b1 !important;

    }

    .btn-valider {
        width: 100px !important;
        text-align: center;
        color: white;
        float: right !important;
        min-width: 100px !important;
        padding-top: 10px !important;
    }

    .background-white {
        margin-left: 10% !important;
        margin-right: 10% !important;
        width: 80% !important;
        max-width: 1000px;
        min-width: 700px;
    }

</style>