import 'custom-event-polyfill/polyfill'
import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import router from '@/router'
import App from './App.vue'

import Vuetable from 'vuetable-2'
Vue.component("vuetable", Vuetable);

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import { Compte } from '@/utils'
Vue.use(Compte);

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue);

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import Modal from '@/components/Modal.vue'
Vue.component('modal', Modal)

import pace from 'pace-js-amd-fix/pace'

pace.start();

Vue.config.productionTip = false;

import api from '@/api'

window.stayHomeSimulateurVueApp = function(DossierId) {
  return new Vue({
    router,
    render: h => h(App),
    beforeCreate() {
      if (process.env.NODE_ENV !== 'development') {
        this.$router.push({name: 'Simulateur', params: {dossierId : DossierId}})
      }
    }
  });
}

window.stayHomeEnvoiDossierVueApp = function(DossierId) {
  return new Vue({
    router,
    render: h => h(App),
    beforeCreate() {
      if (process.env.NODE_ENV !== 'development') {
        this.$router.push({name: 'EnvoiDossier', params: {dossierId : DossierId}})
      }
    }
  });
}


if (document.querySelector('.vuejs_app')) {
  window.stayHomeSimulateurVueApp().$mount('.vuejs_app');
}

