<template>
    <router-view v-if="charge"></router-view>
</template>

<script>
  import api from '@/api'

  export default {
    name: 'Main',
    beforeRouteEnter(to, from, next) {
      if (process.env.NODE_ENV === 'development') {
        api.configurer(null)
        next()
        return
      }
      
      api.jetonRecuperer()
        .then(({data}) => {
          api.configurer(data.jeton)
          next()
        })
    },
    created() {
      this.compteCharger()
    },
    data() {
      return {
        charge: false,
      }
    },
    methods: {
      compteCharger(compte) {
        api.compteCharger().then(d => {
          this.charge = true
          this.$compte.champsModifier(d.data)
        })
      }
    }
  }
</script>
