<template>
    <div style="width: 100%">
        <h3>Editez les informations du client :</h3>

        <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['CiviliteId'])}">Civilité *</label>
        <select
                class="form-control"
                v-model="recapitulatif['CiviliteId']"
        >
            <option :value="null">Sélectionner</option>
            <option value="1">Mademoiselle</option>
            <option value="2">Madame</option>
            <option value="3">Monsieur</option>
            <option value="4">Société</option>
        </select>

        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Nom'])}">Nom *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Nom']">
            </div>
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Prenom'])}">Prenom *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Prenom']">
            </div>
        </div>

        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Email'])}">Email *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Email']">
            </div>
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Age'])}">Age *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Age']">
            </div>
        </div>

        <div class="input_text_stayhome" style="margin-top: 15px">
            <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Telephone'])}">Telephone *</label>
            <input class="form-control" type="text" v-model="recapitulatif['Telephone']">
        </div>

        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label>Situation familliale</label>
                <select class="form-control" v-model.number="recapitulatif['SituationFamilialeId']">
                    <option value="0"></option>
                    <option value="1">Célibataire</option>
                    <option value="2">Concubin</option>
                    <option value="3">Marié</option>
                    <option value="4">Veuf</option>
                    <option value="5">Séparé</option>
                    <option value="6">Divorcé</option>
                    <option value="7">PACS</option>
                    <option value="8">Union libre</option>
                </select>

            </div>
        </div>

        <div class="input_text_stayhome" style="margin-top: 15px">
            <label>Nombre d'enfant</label>
            <input class="form-control" type="text" v-model="recapitulatif['EnfantACharge']">
        </div>

        <div class="partie-conjoint">
            <label style="text-align: center">Présence d'un conjoint</label>
            <input type="checkbox" :checked="this.recapitulatif['Conjoint']['CiviliteId'] ? true : false" v-on:click="gestion_conjoint()">

            <div v-if="recapitulatif['Conjoint']['CiviliteId']">
                <label  v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Conjoint']['CiviliteId'])}">Civilité *</label>
                <select
                        class="form-control"
                        v-model="recapitulatif['Conjoint']['CiviliteId']"

                >
                    <option value="1">Mademoiselle</option>
                    <option value="2">Madame</option>
                    <option value="3">Monsieur</option>
                    <option value="4">Société</option>
                </select>

                <div class="ligne_input_stayhome">
                    <div class="input_text_stayhome">
                        <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Conjoint']['Nom'])}">Nom *</label>
                        <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Nom']">
                    </div>
                    <div class="input_text_stayhome">
                        <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Conjoint']['Prenom'])}">Prenom *</label>
                        <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Prenom']">
                    </div>
                </div>

                <div class="ligne_input_stayhome">
                    <div class="input_text_stayhome">
                        <label>Email</label>
                        <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Email']">
                    </div>
                    <div class="input_text_stayhome">
                        <label>Age</label>
                        <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Age']">
                    </div>
                </div>

                <div class="input_text_stayhome" style="margin-top: 15px">
                    <label>Telephone</label>
                    <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Telephone']">
                </div>
            </div>
        </div>

        <div style="margin-top: 15px">
            <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Commentaire'])}">Description de la situation ({{this.longueur_description - recapitulatif['Commentaire'].length}} caractères) : *</label>
            <textarea style="resize: none; width: 100%; height: 100px;"
                      v-bind:class=" recapitulatif['Commentaire'].length > 1000 ? 'input_erreur': '' "
                      v-model="recapitulatif['Commentaire']"
            ></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InformationsClient",
        props: {
            recapitulatif: Object,
            champManquant: Boolean
        },
        data() {
            return {
                longueur_description: 1000,
            }
        },
        methods: {
            supprimer_conjoint() {
                for (const property in this.recapitulatif["Conjoint"]) {
                    this.recapitulatif["Conjoint"][property] = null;
                }
            },
            gestion_conjoint() {
                if(this.recapitulatif['Conjoint']['CiviliteId']) {
                    this.supprimer_conjoint();
                } else {
                    this.recapitulatif['Conjoint']['CiviliteId'] = 3
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
    .input_text_stayhome {
        display: inline-block;
        width: 49%;

        input {
            width: 90%;
        }

        select {
            height: 35px !important;
            width: 90%;
        }
    }

    .ligne_input_stayhome {
        margin-top: 15px;
    }

    .partie-conjoint {
        margin-top: 10px;
        border-top: solid 1px black;
        padding-top: 10px;
        border-bottom: solid 1px black;
        padding-bottom: 25px;
        margin-bottom: 10px;
    }

    .champManquant {
        color: red;
    }
</style>