<template>
    <div style="width: 100%">
        <h3>Editez l'adresse :</h3>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Adresse']['Adresse'])}">Adresse *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Adresse']['Adresse']">
            </div>
        </div>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Adresse']['CodePostal'])}">CP *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Adresse']['CodePostal']">
            </div>
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Adresse']['Ville'])}">Ville *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Adresse']['Ville']">
            </div>
        </div>

        <h3>Editez les revenus du client :</h3>
        <div class="input_text_stayhome">
            <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['Profession'])}">Profession *</label>
            <input class="form-control" type="text" v-model="recapitulatif['Profession']">
        </div>
        <div style="margin-top: 15px">
            <label>Type de contrat</label>
            <select
                    class="form-control"
                    v-model="recapitulatif['TypeContratId']"
            >
                <option :value="null">Sélectionner</option>
                <option value="1">CDI</option>
                <option value="2">CDD</option>
                <option value="3">Interim</option>
                <option value="4">Autre</option>
            </select>
        </div>
        <div class="input_text_stayhome" style="margin-top: 15px">
            <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['RevenusDepenses']['RevenuAnnuel'])}">Revenus annuels *</label>
            <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['RevenuAnnuel']">
        </div>
        <div style="margin-top: 15px">
            <label>Type autres revenus</label>
            <select
                    class="form-control"
                    v-model="recapitulatif['RevenusDepenses']['RevenusNatureId']"
            >
                <option :value="null">--- Veuillez sélectionner ---</option>
                <option value="1">Pensions Invalidité</option>
                <option value="2">Loyers</option>
                <option value="3">Dividendes</option>
                <option value="4">Autres revenus</option>
            </select>
        </div>
        <div class="input_text_stayhome" style="margin-top: 15px">
            <label>Autres revenus</label>
            <input class="form-control" type="text" v-model="recapitulatif['RevenusDepenses']['AutreRevenu']">
        </div>
        <div v-if="recapitulatif['Conjoint']['CiviliteId']">
            <h3>Editez les revenus du conjoint :</h3>
            <div class="input_text_stayhome">
                <label>Profession *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Profession']">
            </div>
            <div style="margin-top: 15px">
                <label>Type de contrat *</label>
                <select
                        class="form-control"
                        v-model="recapitulatif['Conjoint']['TypeContratId']"
                >
                    <option :value="null">Sélectionner</option>
                    <option value="1">CDI</option>
                    <option value="2">CDD</option>
                    <option value="3">Interim</option>
                    <option value="4">Autre</option>
                </select>
            </div>
            <div class="input_text_stayhome" style="margin-top: 15px">
                <label>Revenus annuels *</label>
                <input class="form-control" type="text" v-model="recapitulatif['Conjoint']['Revenu']">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InformationsAdresse",
        props: {
            recapitulatif: Object,
            champManquant: Boolean
        },
    }
</script>

<style lang="scss" scoped>
    .input_text_stayhome {
        display: inline-block;
        width: 49%;

        input {
            width: 90%;
        }

        select {
            height: 35px !important;
            width: 90%;
        }
    }

    .ligne_input_stayhome {
        margin-top: 15px;
    }

    .champManquant {
        color: red;
    }
</style>