<template>
    <div style="width: 100%">
        <h3>Dossier envoyé par :</h3>
        <div class="ligne_input_stayhome">
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['FullNamePartner'])}">Nom Prénom *</label>
                <input class="form-control" type="text" v-model="recapitulatif['FullNamePartner']">
            </div>
            <div class="input_text_stayhome">
                <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['EmailPartner'])}">Email *</label>
                <input class="form-control" type="text" v-model="recapitulatif['EmailPartner']">
            </div>
        </div>
        <div class="input_text_stayhome">
            <label v-bind:class="{'champManquant' : (champManquant && !recapitulatif['PhonePartner'])}">Téléphone *</label>
            <input class="form-control" type="text" v-model="recapitulatif['PhonePartner']">
        </div>
        <div>
            <label v-bind:class="{'champManquant' : (champManquant && recapitulatif['DirectContact'] === 0)}">Traitement du dossier *</label>
            <select class="form-control" v-model.number="recapitulatif['DirectContact']">
                <option value="0">--- Qui faut-il appeler? ---</option>
                <option value="true">Le partenaire (Vous)</option>
                <option value="false">Le client</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InformationsInfo",
        props: {
            recapitulatif: Object,
            champManquant: Boolean
        },
    }
</script>

<style lang="scss" scoped>
    .input_text_stayhome {
        display: inline-block;
        width: 49%;

        input {
            width: 90%;
        }
    }

    select {
        height: 35px !important;
        width: 49% !important;
    }

    .ligne_input_stayhome {
        margin-top: 15px;
    }

    .champManquant {
        color: red;
    }
</style>