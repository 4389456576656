<template>
    <div class="modal-container">
        <div
            class="modal-background"
            @click="close"
        ></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            close: Function
        }
    }
</script>

<style lang="scss" scoped>

</style>
