import VueRouter from 'vue-router'
import Simulateur from '@/views/Simulateur'
import Main from '@/views/Main'
import EnvoiDossier from "../views/EnvoiDossier";

const routes = [
  {
    path: '/',
    component: Main,
    redirect: { name: 'Simulateur' },
    children: [
      {
        path: 'simulateur/:dossierId',
        name: 'Simulateur',
        component: Simulateur,
      },
      {
        path: 'envoiDossier/:dossierId',
        name: 'EnvoiDossier',
        component: EnvoiDossier
      }
    ]
  }
]

export default new VueRouter({
  routes // short for `routes: routes`
})
